var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-settings"},[_c('page-title',{attrs:{"title":_vm.$t('common_settings_tab_common')},scopedSlots:_vm._u([(_vm.isNewChatConfig(_vm.$store.state.chatState.chat))?{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"btn-success",on:{"click":_vm.saveButtonClick}},[_vm._v(" "+_vm._s(_vm.$t('chat_config_save_button'))+" ")])]},proxy:true}:null],null,true)}),(_vm.protectionTypeIsNotInviteValidation)?_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'allow_invite',
          'hasAnchor': true,
        }
      }}})],1):_vm._e(),_c('a-card',{staticClass:"mt-5"},[_c('config-field',{attrs:{"slot":"title","title":_vm.$t('field_chat_buttons_block_title'),"help-message":_vm.$t('field_chat_buttons_block_help_message')},slot:"title"}),_c('a-alert',{attrs:{"show-icon":""}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('field_chat_buttons_block_description'))},slot:"message"})]),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': this,
          'key': 'chatButtonsMessage',
          'placeholders': _vm.CHAT_BUTTONS_MESSAGE_PLACEHOLDERS,
          'targetToUpload': _vm.groupUploadTarget,
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'options': {
            remove_after: _vm.starterLicenseTag,
            send_after: _vm.starterLicenseTag,
          },
          'customHelpMessage': _vm.GreetingChatButtonsMessageHelpView,
          'hasAnchor': true,
          'hasMedia': true
        },
      }}}),_c('switch-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'chat_buttons_one_time',
          'hasAnchor': true,
        }
      }}})],1),_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{staticClass:"mt-0",class:{ 'mb-0': !_vm.$store.getters.isRuChat },attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'remove_bots_commands',
          'hasAnchor': true,
        }
      }}}),(_vm.$store.getters.isRuChat)?_c('switch-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'enable_command_aliases',
          'hasAnchor': true,
        }
      }}}):_vm._e()],1),_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'ban_user_in_binded_channel',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'send_punish_in_reply',
          'hasAnchor': true,
        }
      }}})],1),_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'allow_send_pm_from_external_sources',
          'tariffTags': _vm.getTagsByFieldKey('allow_send_pm_from_external_sources'),
          'hasAnchor': true,
        }
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }