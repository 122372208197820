import { render, staticRenderFns } from "./JournalSettings.vue?vue&type=template&id=40f867cc&scoped=true&"
import script from "./JournalSettings.vue?vue&type=script&lang=ts&"
export * from "./JournalSettings.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40f867cc",
  null
  
)

export default component.exports